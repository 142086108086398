import {LevelInfo} from "@whilecat/services/dto/level-info.ts";
import {SHELL_COURSE_UUID} from "../content/shell/shell/shell-constants.js";

export const mockLevels = new Map(
    [[
        SHELL_COURSE_UUID,
        {
            ok: true,
            enrolled: true,
            levels: [
                "Learning to Read",
                "Learning to Write",
                "Getting Organised",
                "Getting Rid of Things",
                "Relative or Absolute",
                "Copy That",
                "The Moves",
                "Sorting it out",
                "Heads or tails?",
                "Pipes",
                "Searching for it",
                "Environment variables",
                "History",
            ].map((title, index) => new LevelInfo(
                index+1,
                title,
                `/courses/shell/shell/lesson-${index+1}`,
                `shell/shell/lesson-${index+1}.ts`,
                index < 12, // Is completed?
            )),
        }
    ]]
);
