import * as Sentry from '@sentry/browser'
import { getUserId } from "@whilecat/core/utils/cookie-utils.ts";

export const initSentry = () => {
    Sentry.init({
      dsn: "https://8cb74c7dea72e013c52a12ddc21087bf@o4508610542108672.ingest.de.sentry.io/4508610547286096",
      maxBreadcrumbs: 50,
      debug: true,
      // Ideally "/api/4508610547286096/envelope/" should be added by Caddy, but I couldn't
      // get it to work.
      tunnel: "/public/sentry-tunnel/api/4508610547286096/envelope/",
      beforeSend: event => {
          try {
              const userId = getUserId();
              if (userId) { Sentry.setUser({...event.user, id: userId }) }
          } catch (e) {
              console.error(e);
          }

          return event;
      }
    });
}
